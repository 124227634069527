import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useUserStore from '../stores/useUserStore';
import useShopStore from '../stores/useShopStore';
import '../assets/css/DashboardScreen.css';

const DashboardScreen = () => {
  const email = useUserStore((state) => state.email);
  const clearEmail = useUserStore((state) => state.clearEmail);
  const { shops, setShops, addShop } = useShopStore();
  const navigate = useNavigate();
  const [newShopName, setNewShopName] = useState('');
  const [newShopType, setNewShopType] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchShops = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found');
        }
        const response = await fetch(`https://api.bolhub-connector.nl/shops?email=${email}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch shops');
        }
        const data = await response.json();
        setShops(data);
      } catch (err) {
        setError('Error fetching shops. Please try again later.');
        console.error('Error fetching shops:', err);
        if (err.message === 'No token found') {
          navigate('/login');
        }
      }
    };

    fetchShops().then(r => r);
  }, [email, setShops, navigate]);

  const handleLogout = async () => {
    try {
      const response = await fetch('https://api.bolhub-connector.nl/logout', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (response.ok) {
        localStorage.removeItem('token');
        clearEmail();
        navigate('/login');
      } else {
        console.error('Logout failed');
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const handleAddShop = async () => {
    if (newShopName && newShopType) {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No authentication token found');
        }

        const response = await fetch('https://api.bolhub-connector.nl/shops', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({ email, shopName: newShopName, shopType: newShopType }),
        });

        // Check the response's content type
        const contentType = response.headers.get('Content-Type');

        let responseData;
        if (contentType && contentType.includes('application/json')) {
          // Parse JSON response if it's in JSON format
          responseData = await response.json();
        } else {
          // Handle plain text response
          responseData = await response.text();
        }

        if (!response.ok) {
          throw new Error(responseData.message || 'Failed to add shop');
        }

        // If responseData is not an object (e.g., plain text), create a dummy shop object
        if (typeof responseData !== 'object') {
          responseData = { id: Date.now(), name: newShopName, type: newShopType };
        }

        addShop(responseData);  // Add the new shop to the state
        setNewShopName('');  // Clear the input fields
        setNewShopType('');
        setError('');  // Clear any existing errors

      } catch (err) {
        setError('Error adding shop. Please try again.');
        console.error('Error adding shop:', err.message || err);  // Log the error for debugging
      }
    }
  };

  return (
    <div className="dashboard-container">
      <nav className="navbar">
        <ul className="nav-items">
          <li><a href="/dashboard">Home</a></li>
          <li><a href="/products">Products</a></li>
          <li><a href="/orders">Orders</a></li>
        </ul>
        <button className="logout-button" onClick={handleLogout}>Logout</button>
      </nav>
      <main className="dashboard-content">
        <h1>Welcome to Your Dashboard!</h1>
        <p>Welcome, {email}!</p>

        <section className="shop-management">
          <h2>Manage Your Shops</h2>
          {error && <p className="error-message">{error}</p>}
          <div className="connected-shops">
            <h3>Connected Shops</h3>
            {shops.length === 0 ? (
              <p>No shops connected yet.</p>
            ) : (
              <ul>
                {shops.map(shop => (
                  <li key={shop.id}>{shop.name} - {shop.type}</li>
                ))}
              </ul>
            )}
          </div>
          <div className="add-shop">
            <h3>Add New Shop Connection</h3>
            <input
              type="text"
              placeholder="Shop Name"
              value={newShopName}
              onChange={(e) => setNewShopName(e.target.value)}
            />
            <select
              value={newShopType}
              onChange={(e) => setNewShopType(e.target.value)}
            >
              <option value="">Select Shop Type</option>
              <option value="TikTok">TikTok</option>
              <option value="bol.com">bol.com</option>
            </select>
            <button onClick={handleAddShop}>Add Shop</button>
          </div>
        </section>
      </main>
    </div>
  );
};

export default DashboardScreen;
import React from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import {useLocation, useNavigate} from 'react-router-dom';
import useUserStore from "../stores/useUserStore";
import '../assets/css/WelcomeScreen.css';

const WelcomeScreen = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const setEmail = useUserStore((state) => state.setEmail);

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const shopId = query.get('shop_id');

  const onSubmit = async (data) => {
    try {
      const response = await axios.post('https://api.bolhub-connector.nl/register', {
        ...data,
        shop_id: shopId, // Pass the shop_id to the backend
      });
      if (response.status === 200) {
        setEmail(data.email); // Update Zustand store
        navigate('/dashboard'); // Redirect to dashboard
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div className="welcome-container">
      <h1>Welcome to Bolhub</h1>
      <p>Create your account to continue</p>
      <form onSubmit={handleSubmit(onSubmit)} className="welcome-form">
        <div className="form-group">
          <label htmlFor="email">Email Address</label>
          <input
            id="email"
            type="email"
            {...register('email')}
            className={`form-input ${errors.email ? 'error' : ''}`}
          />
          {errors.email && <p className="error-message">{errors.email.message}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            id="password"
            type="password"
            {...register('password')}
            className={`form-input ${errors.password ? 'error' : ''}`}
          />
          {errors.password && <p className="error-message">{errors.password.message}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="confirmPassword">Confirm Password</label>
          <input
            id="confirmPassword"
            type="password"
            {...register('confirmPassword')}
            className={`form-input ${errors.confirmPassword ? 'error' : ''}`}
          />
          {errors.confirmPassword && <p className="error-message">{errors.confirmPassword.message}</p>}
        </div>
        <button type="submit" className="submit-button">Submit</button>
      </form>
    </div>
  );
};

export default WelcomeScreen;

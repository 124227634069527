import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useShopStore = create(
  persist(
    (set) => ({
      shops: [],
      setShops: (shops) => set({ shops }),
      addShop: (shop) => set((state) => ({ shops: [...state.shops, shop] })),
      removeShop: (shopId) => set((state) => ({
        shops: state.shops.filter(shop => shop.id !== shopId),
      })),
      updateShop: (updatedShop) => set((state) => ({
        shops: state.shops.map(shop =>
          shop.id === updatedShop.id ? updatedShop : shop
        ),
      })),
      clearShops: () => set({ shops: [] }),
    }),
    {
      name: 'shop-storage', // The name of the item in storage (key in localStorage)
    }
  )
);

export default useShopStore;
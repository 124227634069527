import React from 'react';

import { ChakraProvider } from '@chakra-ui/react'

import { Route, Routes, Navigate } from 'react-router-dom';
import WelcomeScreen from './screens/WelcomeScreen';
import DashboardScreen from './screens/DashboardScreen';
import LoginScreen from './screens/LoginScreen';

const App = () => {
  return (
    <ChakraProvider>
      <Routes>
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path="/welcome" element={<WelcomeScreen />} />
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/dashboard" element={<DashboardScreen />} />
        {/* Define other routes here */}
      </Routes>
    </ChakraProvider>
  );
};

export default App;
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useUserStore = create(
  persist(
    (set) => ({
      email: '',
      setEmail: (email) => set({ email }),
      clearEmail: () => set({ email: '' }),
    }),
    {
      name: 'user-storage', // name of the item in storage
    }
  )
);

export default useUserStore;


